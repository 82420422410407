.views-by-advice-type {
  $prophylaxisColor: #f46d43;
  $therapyColor: #a6d96a;

  .sector {
    &.therapy {
      fill: $therapyColor;
    }
    &.prophylaxis {
      fill: $prophylaxisColor;
    }
  }

  .legend-item.therapy .item-color {
    background-color: $therapyColor;
  }
  .legend-item.prophylaxis .item-color {
    background-color: $prophylaxisColor;
  }
}
