.feedback-widget {
  &-wrapper,
  &-button {
    position: fixed;
    bottom: 1em;
    right: 0.5em;
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }

  &-button {
    z-index: 999;
    padding: 3px 10px;
    background-color: #0d6efd;
    color: #fff;
    border: 0;
    border-radius: 3px;
    font-size: 0.9em;

    &:hover {
      background-color: #0c5cd2;
    }
  }

  &-wrapper {
    max-width: 40%;
    min-width: 20em;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0d6efd;
    color: #fff;
    border-radius: 3px 3px 0 0;

    .widget-title {
      padding: 0 15px;
    }

    .close-widget-button {
      background-color: transparent;
      color: inherit;
      border: 0;
      padding: 5px 15px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &-body {
    overflow-y: auto;
    max-height: 80vh;
    padding: 15px;
    border: 1px solid #d7d7d7;
    border-top-width: 0;
    border-radius: 0 0 3px 3px;
    background-color: #fff;
    color: #333;
  }
}

.feedback-form-selector {
  .form {
    &-list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-item {
      padding-bottom: 0.5em;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &-button {
      width: 100%;
      padding: 10px;
      background-color: transparent;
      color: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 3px;
      line-height: 1.2;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &-name,
    &-description {
      display: block;
    }

    &-name {
      font-size: 1.1em;
      font-weight: bold;
    }

    &-description {
      color: #5e5e5e;
    }
  }
}

.feedback-form-wrapper {
  position: relative;

  .sending-overlay {
    position: absolute;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(1.5px);
  }
}

.field-attachments {
  input {
    display: none;
  }

  .dropzone {
    padding: 10px 15px;
    background-color: #f7f7f7;
    border: 1px dashed #ccc;
    border-radius: 3px;
    color: #838383;
    text-align: center;
    font-size: 15px;

    &.disabled {
      opacity: 0.4;
    }

    &.drag-over {
      border-color: #0d6efd;
      border-width: 2px;
    }
  }

  .thumbnails {
    text-align: center;

    &.disabled {
      opacity: 0.4;
    }

    .thumbnail {
      position: relative;
      display: inline-block;
      padding: 1px;
      margin: 5px 2px;
      border: 1px solid #ccc;

      img {
        max-width: 100px;
      }

      &-remove {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        width: 1em;
        height: 1em;
        line-height: 1em;
        font-size: 1.5em;
        border: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
      }
    }
  }
}

.form-actions {
  margin-top: 1em;

  .button {
    padding: 0.3em 1em;
    border-radius: 0.3em;

    &:not(:last-child) {
      margin-right: 0.4em;
    }

    &-cancel {
      background-color: #fff;
      border: 1px solid #dc3545;
      color: #dc3545;

      &:hover {
        background-color: #dc3545;
        color: #fff;
      }
    }

    &-submit {
      background-color: #0d6efd;
      color: #fff;
      border: 1px solid #0d6efd;

      &:hover {
        background-color: #0c5cd2;
        border-color: #0c5cd2;
      }
    }
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &-label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.3em;
  }

  &-input {
    width: 100%;
    font-size: inherit;
  }

  &-description {
    margin: 0.3em 0 0 0;
    font-size: 0.9em;
    color: #555;
  }

  .field-hidden {
    display: none;
  }

  &.required {
    .field-label::after {
      content: '*';
      color: red;
    }
  }

  &-type {
    &-checkbox {
      .field-label {
        display: inline;
      }

      .field-input {
        width: auto;
        margin-right: 0.2em;
      }
    }
  }
}
