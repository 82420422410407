.options-group {
  margin-bottom: 1.5em;
  padding: 0.4em 0 0.8em 0;
  background-color: #f5f5f5;
  border-radius: 3px;
}

.total-views {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  &-value {
    font-size: 5em;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #4c4c4c;
    margin: 0;
  }

  &-title {
    text-align: center;
    font-size: 1.5em;
    line-height: 0.5;
    color: #4c4c4c;
  }
}

.top-one-in-category {
  .category-group {
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    .item-name {
      font-weight: bold;
    }
  }
}

.top-items {
  font-size: 0.9em;

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #dee2e6;
  }
}

.no-data-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;

  &-icon {
    font-size: 3em;
    color: #ccc;
    margin-bottom: 1rem;
  }
}

.date-select {
  display: flex;
}
