@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  word-break: break-all;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.btn:hover {
    text-decoration: none;
  }
}

.btn-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', serif;
  font-weight: 700;
}
