.applications {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .main--wrapper {
    display: flex;
    margin-bottom: 100px;
    justify-content: center;

    .app-dashboard {
      width: 100%;
      max-width: 860px;

      .container-fluid {
        .space-name {
          display: flex;
          align-items: center;
          border-bottom: 2px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 0.5rem;

          .active-space-mark {
            margin-left: 0.5em;
            font-size: 0.5rem;
            color: rgba(0, 0, 0, 0.3);
            cursor: default;
          }
        }

        .group-name {
          font-size: 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 0.5rem;
          padding: 0.5rem 0;
          color: #6d6d6d;
        }

        .app-item--wrapper {
          border-radius: 8px;
          height: 100%;

          &:hover {
            background-color: #d6d6d6;
            text-decoration: none;
          }

          a {
            display: block;
            height: 100%;
          }

          &.active-link {
            background-color: #b1c2e8;
          }

          .app-item {
            display: block;
            overflow: hidden;
            color: #212529;
            box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0);
            transition: box-shadow 150ms;
            text-align: center;
            padding-top: 0.75rem;
            word-break: normal;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }

            .app-image {
              width: 84px;
              height: 84px;
              background-color: #f0f0f0;
              background-size: cover;
              background-position: center;
              border-radius: 8px;
              margin: auto;
            }

            .app-name {
              padding: 0.3em;
              font-family: 'Lato', sans-serif;
              font-size: 0.85rem;
            }
          }
        }
      }
    }

    .app-desc {
      width: 420px;
      background-color: #fff;

      .content {
        position: sticky;
        top: 100px;
        bottom: 0;
        padding: 0 1rem 1rem;
        max-height: 100vh;
        overflow-y: auto;
      }

      &__header {
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        h3 {
          margin-bottom: 0;
          padding-bottom: 0.5rem;
          margin-right: 0.5rem;
        }

        button {
          border: none;
          background-color: transparent;
          font-size: 1.6rem;
          padding: 0;
          color: #969696;
          outline: none;

          &:hover {
            color: #b9b9b9;
          }
        }
      }

      &__body {
        margin-bottom: 1rem;

        a {
          padding: 0.75rem 1rem;
          text-align: center;
          display: block;
          background-color: #007bff;
          color: #fff;
          border-radius: 5px;
          text-decoration: none;

          &:hover {
            background-color: #005fc5;
          }
        }
      }

      &__news {
        background-color: #f2f2f2;
        padding: 0.75rem;
        border-radius: 5px;
        margin-top: 40px;

        h5 {
          font-size: 1.2rem;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.applications-button {
  margin-left: 0.75rem;

  .apps-link,
  .apps-icon {
    display: inline-block;
  }

  .apps-link {
    fill: #53585d;

    &:hover {
      fill: #333;
    }
  }
}

@media (max-width: 767px) {
  .applications {
    .main--wrapper {
      h3,
      h5 {
        text-align: center;
      }

      .app-desc {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 76px;
        display: none;

        &.active-app {
          display: block;
        }

        .content {
          position: static;
        }
      }
    }
  }
}
