$legend: (
  'adenovirus': '#8c510a',
  'cytomegalovirus': '#3288bd',
  'enterovirus': '#1a9850',
  'epstein-barr-virus': '#f16913',
  'human-alphaherpesvirus-1': '#253494',
  'human-alphaherpesvirus-2': '#df65b0',
  'human-coronavirus-covid-19': '#d53e4f',
  'human-coronavirus': '#762a83',
  'human-metapneumovirus': '#cab2d6',
  'human-parechovirus': '#fee391',
  'influenza-a': '#61cdbb',
  'influenza-b': '#f47560',
  'metapneumovirus': '#cab2d6',
  'norovirus': '#74add1',
  'parainfluenza-1': '#b2182b',
  'parainfluenza-3': '#a6d96a',
  'parainfluenza-4': '#2d004b',
  'respiratory-syncytial-virus': '#02818a',
  'rhinovirus': '#01665e',
  'varicella-zostervirus': '#00441b',
);

.idm-reports svg.chart {
  background-color: #f5f5f5;
}

.guide {
  stroke: #ddd;
  stroke-width: 1px;
  stroke-dasharray: 5;
}

.legend {
  margin-top: 0.5em;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

  li {
    margin-right: 1.2em;
  }

  .symbol {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.3em;
    background-color: #ccc;
    border-radius: 50%;
  }
}

.legend-mark {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 0.3em;
  background-color: #ccc;
  border-radius: 50%;
}

@each $virus, $color in $legend {
  .legend-item.#{$virus} .symbol {
    background-color: #{$color};
  }

  .legend-mark.#{$virus} {
    background-color: #{$color};
  }

  .legend-table-mark.#{$virus} {
    background-color: #{$color};
  }
}

.not-in-focus {
  fill: #eee;
  stroke: #eee;
  opacity: 0.15;
}
