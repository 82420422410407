.views-by-category {
  $pageColor: #f47560;
  $medicationColor: #e8a838;
  $adviceGroupColor: #61cdbb;

  .sector {
    &.advice-group {
      fill: $adviceGroupColor;
    }
    &.medication {
      fill: $medicationColor;
    }
    &.page {
      fill: $pageColor;
    }
  }

  .legend-item.advice-group .item-color {
    background-color: $adviceGroupColor;
  }
  .legend-item.medication .item-color {
    background-color: $medicationColor;
  }
  .legend-item.page .item-color {
    background-color: $pageColor;
  }
}
