@use '../viruses.scss' as vars;

.viruses-dynamics {
  .series {
    .line {
      fill: none;
      stroke: #555;
      stroke-width: 1;
      stroke-opacity: 0.2;
    }

    .point {
      fill-opacity: 0.8;
    }

    @each $virus, $color in vars.$legend {
      &.#{$virus} {
        .line {
          stroke: #{$color};
        }

        .point {
          fill: #{$color};
        }

        &.in-focus {
          .line {
            stroke-opacity: 0.7;
          }

          .point {
            stroke: #{$color};
            stroke-width: 2px;
          }
        }
      }
    }
  }
}
