.app {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header__wrapper {
    background-color: #e7e6e6;
    border-bottom: 2px solid #b3b3b3;
  }

  &__footer__wrapper {
    background-color: #e7e6e6;
  }

  &__main {
    flex: 1 0;
    display: flex;
    position: relative;
  }
}

@media (min-width: 992px) {
  .app {
    &__main {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
