details {
  margin-bottom: 1rem;

  summary {
    color: #666;
    user-select: none;
  }

  .content {
    padding: 0.6em 0.8em;
    background-color: #f8f8f8;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    font-size: 14px;

    p {
      margin-bottom: 0.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.timeframe {
  margin: 1em 0;

  .axis {
    .domain,
    .tick line {
      stroke: #016483;
    }
  }

  .timeframe-label {
    font-size: 11px;
    font-weight: bold;
    dominant-baseline: hanging;

    &.label-start {
      text-anchor: end;
    }

    &-line {
      stroke: #016483;
      stroke-width: 2px;
      shape-rendering: crispEdges;
    }
  }

  .background {
    fill: #dff2f8;
  }

  .timeframe-overlay {
    fill: black;
    fill-opacity: 0.3;
  }

  .datasets {
    .dataset {
      stroke: #199bc3;
    }
  }
}
