.user-login-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 0 15px;
  }

  .main {
    flex-grow: 1;
  }

  .brand {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    &__logo {
      height: 60px;
      margin-right: 0.5rem;
    }

    &__name {
      margin: 0.8rem 0 1rem 0;
      color: #23348b;
      font-size: 2em;
    }
  }

  .login-block {
    min-height: 550px;
    background-image: url('/img/login-page-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    color: #fff;
  }

  .login-message {
    margin: 3em auto 0 auto;
    padding: 0.8em 2em;
    max-width: 30em;
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(4px);
    border-radius: 3px;

    h2 {
      font-size: 1.8em;
    }

    p {
      margin: 0;
    }
  }
}

@media (min-width: 992px) {
  .user-login-page {
    .login-message {
      margin-right: 0;
    }
  }
}
