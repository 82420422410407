$step-line-color: rgb(110, 110, 110);

.as-workflow {
  .columns {
    display: flex;

    .column {
      flex: 1 1;
    }

    .chart {
      width: 40%;
    }

    .steps {
      width: 30%;
      position: relative;
      padding: 0;

      .step {
        position: absolute;
        overflow-y: auto;
        font-size: 14px;
        padding: 8px;
        border-top: 1px dashed $step-line-color;
      }
    }

    .step-details {
      width: 30%;
      position: relative;
      font-size: 14px;

      .details {
        position: absolute;
        width: 100%;
        padding: 8px 15px;
        border-top: 1px dashed $step-line-color;
      }
    }

    .chart,
    .step-details {
      background-color: #f8f8f8;
    }

    .chart {
      .no-data-message {
        text-anchor: middle;
        font-family: 'Lato', serif;
        font-size: 18px;
        font-weight: bold;
      }

      .step-line {
        stroke: $step-line-color;
        stroke-width: 1px;
        stroke-dasharray: 3;
      }

      .link {
        fill-opacity: 0.2;
      }

      .label {
        font-size: 13px;
        text-anchor: middle;
      }

      .value-label {
        fill: white;
        dominant-baseline: middle;
        font-weight: bold;
      }

      .node-name-label {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: bold;

        &.label-all_patients {
          font-size: 18px;
        }
      }

      .link-percentage-label {
        fill-opacity: 0.65;
        dominant-baseline: hanging;
      }
    }

    .node-badge {
      display: inline-flex;
      align-items: center;
      background-color: #efefef;
      padding: 0 6px;
      border-radius: 3px;

      .color-mark {
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 6px;
        border-radius: 50%;
      }
    }
  }

  .unique-included-patients {
    font-size: 12px;

    .label {
      text-anchor: middle;

      &-all-patients {
        dominant-baseline: hanging;
        font-weight: bold;
      }

      &-unique-patients {
        dominant-baseline: middle;
        fill: white;
        font-weight: bold;
      }
    }
  }
}
