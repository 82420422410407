.message-box {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-content {
    padding: 1em;
    border-radius: 0.3em;
  }
}
