.page-not-found {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__status-code {
    margin-right: 1rem;
    font-family: 'Lato', serif;
    font-weight: 700;
    font-size: 6em;
    color: #b7b7b7;
  }
}

.message {
  &__title,
  &__description {
    margin: 0;
  }

  &__title {
    margin-bottom: 0.2em;
  }

  &__description {
    line-height: 1.2;
  }
}

@media (min-width: 576px) {
  .page-not-found {
    margin-left: 2em;
  }
}
