.chart-views-dynamics {
  .grid {
    .x-mark {
      fill: none;

      &:nth-child(odd) {
        stroke: none;
        fill: #f5f5f5;
      }
    }

    .y-mark {
      fill: #f1f1f1;
    }
  }

  .axis-left {
    .domain {
      display: none;
    }
  }

  .point {
    fill: white;
    stroke: crimson;
    stroke-width: 2;
  }

  .line {
    fill: none;
    stroke: crimson;
    stroke-width: 2;
  }
}
