.idm-drive-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40em;
  background-color: #fff;
}

.directory {
  .dir-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
  }
}

.folders-separator {
  color: #999;
}

.directory-table {
  width: 100%;

  thead {
    border-bottom: 2px solid #ddd;

    tr {
      background-color: #f4f4f4;
    }
  }

  tbody {
    tr:hover {
      background-color: #f4f4f4;
    }
  }

  tr {
    border-bottom: 1px solid #eee;
  }

  td,
  th {
    padding: 8px;
  }

  th {
    &.file-type-icon {
      width: 2.5em;
    }

    &.file-upload-date {
      width: 25%;
    }

    &.file-size {
      width: 12%;
    }
  }

  td {
    &.file-type-icon {
      text-align: center;
    }
  }
}
