.unexpected-error {
  &__container {
    margin: 2em auto 0 auto;
    padding: 1em 2em;
    max-width: 600px;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
  }
}
