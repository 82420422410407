.user-dropdown {
  display: inline-flex;
  align-items: center;

  .user-menu-logo {
    display: flex;

    img {
      height: 1.8rem;
    }
  }

  .dropdown-menu {
    padding: 0.5rem;

    .dropdown-item {
      padding: 0.25rem 0.5rem 0.25rem 1rem;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .dropdown-item[href^='http'] {
      &::after {
        content: '\f35d';
        display: block;
        font-weight: 700;
        font-family: 'Font Awesome 5 Free';
        margin-left: 0.75rem;
      }
    }
  }

  .user-menu-button--wrapper {
    .user-menu-button {
      font-size: 1.1rem;
      padding-left: 0.4rem;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .user-menu-empty {
    padding: 0.375rem 1.75rem 0.375rem 0.4rem;
  }
}
