@use '../viruses.scss' as vars;

.most-frequent-virus {
  .virus {
    padding: 1em;
    background-color: #f5f5f5;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    border-radius: 2px;
  }

  .description {
    .orders-number {
      font-weight: bold;
    }
  }
}

@each $virus, $color in vars.$legend {
  .most-frequent-virus.#{$virus} {
    .virus {
      color: #{$color};
    }
  }
}
