.data-validation-feedback-form {
  min-width: 30em;
}

.section-error-field-group {
  border: 1px solid #ccc;
  padding: 0.5em;
  margin-bottom: 0.5em;

  &:not(.has-error) {
    .field {
      margin: 0;
    }

    .field-error-date,
    .field-error-description {
      display: none;
    }
  }
}
