@use '../viruses.scss' as vars;

.viruses-ratio {
  .donut {
    @each $virus, $color in vars.$legend {
      .sector.#{$virus} {
        fill: #{$color};
      }
    }

    .arc-label {
      font-size: 14px;
      fill: #fff;
      text-anchor: middle;
      dominant-baseline: middle;
      pointer-events: none;
    }
  }

  .sum {
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
