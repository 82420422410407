.portal__header {
  display: flex;
  align-items: center;
  padding: 0.75em 0;
  font-size: 1.1em;

  &__logo {
    margin: 1em 0.5em 1em 0;
  }

  &__menu {
    height: 100%;
  }

  &__user-menu {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
  }

  .org-logo--wrapper {
    a {
      text-decoration: none;

      .org-logo {
        max-height: 50px;
        margin-right: 0.5em;
      }

      .site-name {
        color: #23348b;
        font-weight: 700;
        font-size: 1.25rem;
      }

      &:hover {
        .site-name {
          color: #455bba;
        }
      }
    }
  }

  &__space-info {
    height: 36px;
    width: 36px;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    border: 2px solid #007bff;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 0.5rem;
    outline: none !important;
    color: #007bff;

    &:hover,
    &:active,
    &.is-active {
      background-color: #007bff;
      color: #fff;
    }

    i {
      font-size: 1.1rem;
      pointer-events: none;
    }
  }
}

.tooltip-inner {
  max-width: 500px !important;
}
