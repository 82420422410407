$light-green: #40cb16;
$dark-green: #208900;
$dark-yellow: #e6d60f;
$light-yellow: #ffe200;
$orange: #e89800;
$red: #de3232;
$purple: #7923af;
$pink: #edb0c8;
$light-grey: #f5f5f5;

.quick-overview {
  .charts {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 15px;
    row-gap: 15px;

    .chart {
      padding: 20px 30px;
      background-color: #f5f5f5;
      position: relative;

      &-title {
        text-align: center;
      }

      &.step {
        &::after {
          position: absolute;
          right: -0.5em;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          content: '';
          display: block;
          width: 1em;
          height: 1em;
          background-color: $light-grey;
        }
      }
    }

    .legend {
      font-size: 14px;
      &-item {
        .item-color {
          display: inline-block;
          margin-right: 0.3em;
          width: 1em;
          height: 1em;
          border-radius: 50%;
        }

        .item-data {
          font-size: 14px;
          opacity: 0.7;
        }
      }
    }

    .chart-description {
      margin-top: 0.5em;
      font-size: 14px;
      line-height: 1.2;
    }

    .included-patients {
      .total-patients-circle {
        fill: $light-green;
      }

      .center-circle {
        fill: white;
      }

      .unique-patients-arc {
        fill: $dark-green;
      }

      .labels {
        &-unique {
          text {
            fill: white;
          }
        }
      }

      .legend {
        &-total-included-patients {
          .item-color {
            background-color: $light-green;
          }
        }

        &-unique-included-patients {
          .item-color {
            background-color: $dark-green;
          }
        }
      }
    }

    .included-specialties {
      grid-column: span 6;

      .label {
        text-anchor: middle;

        &.total-patients-value {
          font-weight: bold;
        }

        &.total-patients-text {
          font-size: 14px;
          dominant-baseline: hanging;
        }
      }

      .arcs {
        transition: transform 600ms ease;
      }

      .parts {
        display: grid;
        grid-template-columns: 1fr 200px 1fr;
        column-gap: 30px;

        .part {
          .highlighted-specialty-wrapper {
            position: relative;

            .pointer {
              position: absolute;
              z-index: 1;
              width: 14px;
              height: 14px;
              background-color: #ddd;
              left: 50%;
              top: -7px;
              transform: translateX(-50%) rotate(45deg);
            }

            .highlighted-specialty {
              position: relative;
              z-index: 2;
              margin-top: 15px;
              padding: 8px 16px;
              background-color: #fff;
              border: 1px solid #ddd;
              border-radius: 4px;
              line-height: 1.2;
              text-align: center;

              &.no-active {
                font-size: 14px;
                opacity: 0.7;
              }

              .name {
                font-weight: bold;
                margin-bottom: 0.3em;
              }
            }
          }

          &.legend {
            .color {
              display: inline-block;
              width: 1em;
              height: 1em;
              border-radius: 4px;
              background-color: #aaa;
            }

            table {
              tr {
                &.highlighted {
                  font-weight: bold;
                  background-color: #eee;
                }

                &.empty {
                  opacity: 0.4;
                  font-weight: normal;
                }
              }
            }

            &-left {
              text-align: end;
              overflow-x: auto;

              table {
                margin-left: auto;

                td:not(:last-child) {
                  padding-right: 8px;
                }
              }
            }

            &-right {
              overflow-x: auto;

              table {
                td:not(:first-child) {
                  padding-left: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  .label {
    text-anchor: middle;
    pointer-events: none;

    &-value {
      font-size: 14px;
      font-weight: bold;
    }

    &-percents {
      font-size: 12px;
      dominant-baseline: hanging;
    }

    &-total-patients-value {
      font-weight: bold;
    }

    &-total-patients-text {
      dominant-baseline: hanging;
    }

    &-total-patients-text {
      font-size: 14px;
    }
  }

  .included-patients {
    grid-column: span 4;

    .sector {
      &-total {
        fill: $light-green;
      }
      &-unique {
        fill: $dark-green;
      }
    }
  }

  .reviewed-by-algorithm {
    grid-column: span 4;

    .sector {
      &-not_suspicious {
        fill: $dark-yellow;
      }
      &-suspicious {
        fill: $orange;
      }
    }

    .legend {
      &-suspicious {
        .item-color {
          background-color: $orange;
        }
      }
      &-not_suspicious {
        .item-color {
          background-color: $dark-yellow;
        }
      }
    }
  }

  .consultancy {
    grid-column: span 4;

    .sector {
      &-not_changed_strategy {
        fill: $light-yellow;
      }
      &-changed_strategy {
        fill: $red;
      }
    }

    .labels {
      &-changed_strategy {
        text {
          fill: white;
        }
      }
    }

    .legend {
      &-not_changed_strategy {
        .item-color {
          background-color: $light-yellow;
        }
      }
      &-changed_strategy {
        .item-color {
          background-color: $red;
        }
      }
    }
  }

  .alerts-type,
  .adjustments-type {
    grid-column: span 3;
    font-size: 14px;
  }
}
