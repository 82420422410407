.donut-chart {
  .sector {
    fill: #888;
  }

  .arc-label {
    font-size: 13px;
    fill: #333;
    text-anchor: middle;
    dominant-baseline: middle;
    pointer-events: none;
  }

  .legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
      .item-color {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-inline-end: 3px;
        background-color: #888;
        border-radius: 50%;
      }
    }

    &-item:not(:last-child) {
      margin-inline-end: 15px;
    }
  }
}
