.route-error {
  max-width: 400px;
  margin: 3em auto 0 auto;

  .status-text {
    font-style: italic;
    color: rgb(113, 113, 113);
  }

  footer {
    margin-top: 1em;
    padding-top: 0.2em;
    border-top: 2px solid #ccc;
  }
}
